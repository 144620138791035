<template>
  <div class="pro_box">
    <div class="title flex_center_between_box">
      <div class="title_txt flex_center_start_box"><img src="../../assets/img/detail/title_ic12.png" />Country Trade</div>
      <div class="btn_export flex_center" @click="viewHistory"><img src="../../assets/img/detail/btn_ic4.png" />View History</div>
    </div>

    <div class="top_tab">
      <el-tabs v-model="tabActiveName" type="card" class="tab_box">
        <el-tab-pane label="Import & Export" name="ImportExport" class="result_tab">
          <div class="group profile">
            <div class="item">
              <label class="lab">Total Trade: </label>
              <div class="txt">{{ objTrade.totalTrade ? objTrade.totalTrade : '-' }}</div>
            </div>
            <div class="item">
              <label class="lab">Total Export: </label>
              <div class="txt">{{ objTrade.totalExport ? objTrade.totalExport : '-' }}</div>
            </div>
            <div class="item">
              <label class="lab">Total Import: </label>
              <div class="txt">{{ objTrade.totalImport ? objTrade.totalImport : '-' }}</div>
            </div>
            <div class="item">
              <label class="lab">Trade Net Balance: </label>
              <div class="txt">{{ objTrade.tradeNetBalance ? objTrade.tradeNetBalance : '-' }}</div>
            </div>
            <div class="item">
              <label class="lab">Trade Gap: </label>
              <div class="txt">{{ objTrade.tradeGap ? objTrade.tradeGap : '-' }}</div>
            </div>
            <div class="item">
              <label class="lab">Trade Goods Exports: </label>
              <div class="txt">{{ objTrade.tradeGoodsExports ? objTrade.tradeGoodsExports : '-' }}</div>
            </div>
            <div class="item">
              <label class="lab">Trade Goods Imports: </label>
              <div class="txt">{{ objTrade.tradeGoodsImports ? objTrade.tradeGoodsImports : '-' }}</div>
            </div>
            <div class="item">
              <label class="lab">Trade Services Exports: </label>
              <div class="txt">{{ objTrade.tradeServicesExports ? objTrade.tradeServicesExports : '-' }}</div>
            </div>
            <div class="item">
              <label class="lab">Trade Services Imports: </label>
              <div class="txt">{{ objTrade.tradeServicesImports ? objTrade.tradeServicesImports : '-' }}</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Top Countries" name="TopCountries" class="result_tab">
          <div class="table_box">
            <el-table ref="countriesTable" :data="countriesTable" border style="width: 100%" class="no_page">
              <!-- 空数据状态的插槽 -->
              <template slot="empty"> No data </template>
              <el-table-column type="index" align="center" show-overflow-tooltip :resizable="false" width="70" />
              <el-table-column :label="window.innerWidth>821?'Top 5 importing countries':'Top 5 importing'" prop="ImportingCountries" align="left" show-overflow-tooltip :resizable="false" />
              <el-table-column :label="window.innerWidth>821?'Top 5 exporting countries':'Top 5 exporting'" prop="ExportingCountries" align="left" show-overflow-tooltip :resizable="false" />
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Top Products" name="TopProducts" class="result_tab">
          <div class="table_box">
            <el-table ref="ProductsTable" :data="ProductsTable" border style="width: 100%" class="no_page">
              <!-- 空数据状态的插槽 -->
              <template slot="empty"> No data </template>
              <el-table-column type="index" align="center" show-overflow-tooltip :resizable="false" width="70" />
              <el-table-column  :label="window.innerWidth>821?'Top 5 importing products':'Top 5 importing '" prop="ImportingProducts" align="left" show-overflow-tooltip :resizable="false" />
              <el-table-column  :label="window.innerWidth>821?'Top 5 exporting countries':'Top 5 exporting'" prop="ExportingProducts" align="left" show-overflow-tooltip :resizable="false" />
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { searchCountryInfo } from '@/api/companyApi.js'
import crypto from '@/utils/crypto'
export default {
  data() {
    return {
      tabActiveName: 'ImportExport',
      objTrade: {
        totalTrade: '',
        totalImport: '',
        totalExport: '',
        tradeNetBalance: '',
        tradeGap: '',
        tradeGoodsExports: '',
        tradeGoodsImports: '',
        tradeServicesExports: '',
        tradeServicesImports: '',
      },
      countriesTable: [],
      ProductsTable: [],
      companyCountry:'',
      window:window
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.companyCountry = this.$route.query.companyCountry

      const params = {
        types: ['CT'],
        countryCodes: [this.companyCountry],
        // years: [''],
      }
      searchCountryInfo(params).then((result) => {
        let data = JSON.parse(crypto.decrypt(result))
        if (data && data.code && data.data) {
          console.log('result- trade', data.data)
          let obj = data.data.countryTradeList ? data.data.countryTradeList[0] : ''
          this.objTrade = {
            totalTrade: obj.totalTrade,
            totalImport: obj.totalImport,
            totalExport: obj.totalExport,
            tradeNetBalance: obj.tradeNetBalance,
            tradeGap: obj.tradeGap,
            tradeGoodsExports: obj.tradeGoodsExports,
            tradeGoodsImports: obj.tradeGoodsImports,
            tradeServicesExports: obj.tradeServicesExports,
            tradeServicesImports: obj.tradeServicesImports,
          }
        
          obj.topFiveImportingCountries.map((item,index)=>{
            this.countriesTable.push({ImportingCountries:item,ExportingCountries:obj.topFiveExportingCountries[index]})

          })
        
          obj.topFiveExportingProducts.map((item,index)=>{
            this.ProductsTable.push({ExportingProducts:item,ImportingProducts:obj.topFiveImportingProducts[index]})
          })
        
        }
      })
    },

    //跳转
    viewHistory() {
      this.$router.push({ path: '/country/viewhistory', query: { companyCountry: this.companyCountry } })
    },
  },
}
</script>
<style scoped>
.title {
  margin-bottom: 20px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}
.pro_box {
  margin-top: 22px;
}
.group.profile {
  padding: 0 10px;
}
.profile .item {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7eaed;
}
.profile .item:first-child {
  padding-top: 10px;
}
.profile .item:last-child {
  padding-bottom: 10px;
  border-width: 0;
}
.profile .item .lab {
  width: 178px;
  font-size: 16px;
  color: #8497ab;
}
.profile .item .txt {
  margin-left: 40px;
  color: #022955;
  font-size: 16px;
}
/* tab */
.tab_box /deep/ .el-tabs__header {
  margin-bottom: 10px;
}
.el-tabs--card > .el-tabs__header {
  border: none;
}
.tab_box /deep/ .el-tabs__header,
.tab_box /deep/.el-tabs__content {
  width: 100%;
  box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
  border-radius: 14px;
}
.tab_box /deep/ .el-tabs__header {
  padding: 11px 75px;
}
.result_tab {
  padding: 20px;
}
.tab_box /deep/ .el-tabs__nav-wrap {
  width: 100%;
  height: 28px;
  background: #f2f5f9;
  border-radius: 8px;
  border: 1px solid #e7eaed;
}
.tab_box /deep/ .el-tabs__nav {
  width: 100%;
  border: none;
  padding: 2px 75px;
  display: flex;
  justify-content: space-between;
}
.tab_box /deep/ .el-tabs__item {
  padding: 0 15px;
  width: 150px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #8497ab;
  border: none;
  text-align: center;
}
.tab_box /deep/ .el-tabs__item:last-child {
  padding-right: 15px !important;
}
.tab_box /deep/ .el-tabs__item.is-active {
  background: #ff7600;
  box-shadow: 0px 1px 2px 0px #c44306;
  border-radius: 4px;
  font-family: 'Arial Bold';
  color: #ffffff;
}
.market_box {
  display: flex;
  justify-content: space-between;
}
.tab_box /deep/ .el-table--scrollable-x .el-table__body-wrapper {
  overflow: hidden;
}
.tab_box /deep/ .el-table__body,
.tab_box .el-table__footer,
.tab_box /deep/ .el-table__header {
  width: auto !important;
}
@media (max-width: 821px) {
  .tab_box /deep/ .el-tabs__header {
  padding: 5px !important;
}
.tab_box /deep/ .el-tabs__nav{
  padding: 5px !important;
}
.EconomicOrganizationtitle{
  width: 50% !important;
}.sel_box{
    margin-top: 10px !important;
}
.profile .item .lab {
  width: 100%  !important;
}
.profile .item{
     padding: 10px 0px 0px!important;
}
}

</style>
